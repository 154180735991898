<template>
  <div class="amap-page-container">
    <el-amap
      ref="map"
      vid="amapDemo"
      :amap-manager="amapManager"
      :center="center"
      :zoom="zoom"
      :plugin="plugin"
      class="amap-demo"
    >
      <el-amap-marker
        v-for="(marker, index) in markerList"
        :key="marker.id"
        :position="marker.position"
        :content="getIcon(marker.type, marker.select, marker.remain)"
        :offset="[-21, -42]"
        :vid="index"
        :events="marker.events"
      ></el-amap-marker>
      <el-amap-marker
        v-if="currentPositionShow"
        :position="currentPosition"
        content="<img src='https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%B0%B8%E7%A6%8F/%E5%B0%8F%E7%A8%8B%E5%BA%8F/current_location.png' style='width:42px;height:42px'/>"
        :offset="[-21, -42]"
        vid="currentLoaction"
      ></el-amap-marker>

      <div class="detail-modal-wrap" v-if="selectDevice.id">
        <div class="detail-modal">
          <div class="title-wrap">
            <div class="detail-title">{{ selectDevice.name }}</div>
            <div class="detail-close" @click.stop="detailModalClick"></div>
          </div>
          <div
            class="detail-address"
            v-if="selectDevice.juli"
            @click="gotoLocation(selectDevice)"
          >
            <div style="display: flex; align-items: center">
              <span class="distance">距我{{ selectDevice.juli }}</span>
              <span class="addressTitle">{{ selectDevice.addressText||'到这里' }}</span>
            </div>
            <img
              src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%8F%B3%E8%BE%B9%E7%AE%AD%E5%A4%B4.png"
            />
          </div>

          <!-- <div class="detail-pic-wrap" v-if="selectDevice.headPic">
            <img
              v-if="selectDevice.headPic"
              :src="selectDevice.headPic[0].url"
            />
          </div> -->
          <div v-if="selectDevice.parkingFlag == 1">
            <div class="detail-parking-desc">
              是否免费：{{ selectDevice.ifMianfei ? '收费' : '免费' }}
            </div>
            <div class="detail-parking-desc">
              总停车位：{{ selectDevice.total ? selectDevice.total : '未知' }}
            </div>
            <!-- <div class="detail-parking-desc">剩余停车位：{{ selectDevice.free ? selectDevice.free : '未知' }}</div> -->
          </div>
          <div
            class="audioLine"
            v-if="audiosrc"
            :style="{ width: isPlayAudio ? '324px' : '80px' }"
          >
              <!-- 按钮 -->
              <img
               @click="playAudio"
                src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%92%AD%E6%94%BE.png"
                v-if="isPlayAudio"
              />
              <!-- <text>暂停</text> -->
              <img
               @click="playAudio"
                src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%9A%82%E5%81%9C.png"
                v-if="!isPlayAudio"
              />
            <div class="tiptitle">{{ isPlayAudio ? '讲解中…' : '讲解' }}</div>
            <!-- <text>播放</text> -->
            <div class="slider" v-if="isPlayAudio">
              <van-slider
                v-model="audioTime"
                @change="sliderChange"
                inactive-color="#E0E0E0"
                button-size="0px"
                bar-height="4px"
                active-color="#89B9F3"
                disabled
              />
            </div>

            <div class="audioFont" v-if="false">
              {{ showTime1 }}/<span class="audioFont2">{{ showTime2 }}</span>
            </div>
          </div>
          <div class="describetion" v-if="selectDevice.describetion" @click="gotoAttractions">
            {{ selectDevice.describetion }}
          </div>
          <!-- <div class="go-location" @click="playAudio">到这里去</div> -->
        </div>
      </div>
      <div @click="gotoSearch" class="serchbox">
        <van-icon
          name="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E6%90%9C%E7%B4%A201%402x.png"
          size="15px"
        />
        <span>输入关键词进行搜索</span>
      </div>
    </el-amap>

    <div class="bottomLine" v-if="!ifReturnList && !selectDevice.id">
      <div class="bottom-title">周边</div>
      <div class="bottom-icon-wrap">
        <div
          class="iconArea"
          :class="selectedType === 1 ? 'iconAreaSelect' : ''"
          @click="changeSelectType(1)"
        >
          <img src="./assets/icon1.png" />
          <span>景点</span>
        </div>
        <div
          class="iconArea"
          :class="selectedType === 4 ? 'iconAreaSelect' : ''"
          @click="changeSelectType(4)"
        >
          <img src="./assets/icon2.png" />
          <span>餐饮美食</span>
        </div>
        <div
          class="iconArea"
          :class="selectedType === 5 ? 'iconAreaSelect' : ''"
          @click="changeSelectType(5)"
        >
          <img src="./assets/icon3.png" />
          <span>酒店民宿</span>
        </div>
        <div
          class="iconArea"
          :class="selectedType === 6 ? 'iconAreaSelect' : ''"
          @click="changeSelectType(6)"
        >
          <img src="./assets/icon4.png" />
          <span>购物场所</span>
        </div>
        <div
          class="iconArea"
          :class="selectedType === 11 ? 'iconAreaSelect' : ''"
          @click="changeSelectType(11)"
        >
          <img src="./assets/wenming.png" />
          <span>娱乐</span>
        </div>
      </div>
    </div>

    <div class="rightLine">
      <div
        class="right-item"
        :class="selectedType === 2 ? 'right-item-select' : ''"
        @click="changeSelectType(2)"
      >
        <img src="./assets/icon6.png" v-if="selectedType !== 2" />
        <img src="./assets/select-icon6.png" v-else />
        <span>停车场</span>
      </div>
      <div
        class="right-item"
        :class="selectedType === 3 ? 'right-item-select' : ''"
        @click="changeSelectType(3)"
      >
        <img src="./assets/icon7.png" v-if="selectedType !== 3" />
        <img src="./assets/select-icon7.png" v-else />
        <span>公厕</span>
      </div>
      <div
        class="right-item"
        :class="selectedType === 12 ? 'right-item-select' : ''"
        @click="changeSelectType(12)"
      >
        <img src="./assets/icon8.png" v-if="selectedType !== 12" />
        <img src="./assets/select-icon8.png" v-else />
        <span>加油站</span>
      </div>
      <div
        class="right-item"
        :class="trafficLayer ? 'right-item-select' : ''"
        @click="trafficLayerClick"
      >
        <img src="./assets/icon9.png" v-if="!trafficLayerClick" />
        <img src="./assets/select-icon9.png" v-else />
        <span>路况</span>
      </div>
    </div>
    <div class="right-location" @click="goToCenter">
      <img src="./assets/current-location.png" />
    </div>
    <audio
      :src="audiosrc"
      ref="audio"
      @timeupdate="viodoUpdate"
      @canplay="initialization"
      preload="true"
    ></audio>
  </div>
</template>
<script
  type="text/javascript"
  src="https://unpkg.com/@dcloudio/uni-webview-js@0.0.2/index.js"
></script>
<script
  type="text/javascript"
  src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"
></script>
<script>
import axios from 'axios';
import { Notify, Slider, Icon } from 'vant';
// NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
  key: 'bf8e7cb2ae43312d6b6dafae4efeb62d',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation',
    'AMap.Driving',
    'AMap.ImageLayer',
  ],
  uiVersion: '1.0.11', // 版本号
  v: '1.4.4',
});
import { AMapManager } from 'vue-amap';
let amapManager = new AMapManager();
let trafficLayer = null;
const position = [120.376215, 30.455996];

export default {
  components: {
    [Slider.name]: Slider,
    [Icon.name]: Icon,
  },
  data: function () {
    const this_ = this;
    const token = this_.$route.query.biaotoken;
    const contentId = this_.$route.query.contentId || null;
    const topicSymbol = this_.$route.query.topicSymbol || null;
    const latitude = this_.$route.query.latitude || null;
    const longitude = this_.$route.query.longitude || null;
    const tenantId = this_.$route.query.tenantId || null;
    const ifFengkou = this_.$route.query.ifFengkou || null;
    const title = '梅源村';
    const lat = this_.$route.query.userlat || 0;
    const lon = this_.$route.query.userlon || 0;
    const id = this_.$route.query.id || null;
    const topicSymbolIndex = this_.$route.query.topicSymbolIndex || null;
    return {
      token,
      topicSymbolIndex,
      id,
      audioTime: '',
      audiosrc: '',
      isPlayAudio: false,
      audioSeek: 0,
      audioDuration: 0,
      showTime1: '00:00',
      showTime2: '00:00',
      audioColor: '#1677FF',
      latitude,
      longitude,
      ifFengkou,
      isshow: false,
      // 当前定位点
      currentLocation: [],
      // 选中设备详情
      selectDevice: {},
      contentId: contentId,
      tenantId: tenantId,
      topicSymbol: topicSymbol,
      ifReturnList: contentId ? true : false,
      // 选中的类型
      selectedType: 1,
      amapManager,
      zoom: 12,
      trafficLayer:false,
      center: position,
      currentPosition: [],
      currentPositionShow: false,
      plugin: [],
      mockData: [
        {
          id: '1674660711091056641',

          templateTitle: '旅游景点',

          title: '永福古镇',

          updateDate: '2023-06-30 14:21:58',

          hot: 0,

          clockIn: false,

          published: false,

          content: {
            arUrl: '',

            vrUrl: '',

            box_id: '',

            location: [
              120.376743,

              30.456579,
            ],

            detail_pic: [
              {
                url: 'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/2a86affd2ab4470eb169638740d7c671.png',
              },
            ],

            select_key: '',

            small_link: '',

            suggestHour: '',

            describetion:
              '永福古镇坐落在永福村中心，是一个具有悠久历史和独特风貌的古镇。',

            first_figure:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/87323fb01f834a51b8d1dbb8cb02377a.png',

            location_text: '永福古镇',

            introduction_text:
              '<p>永福古镇坐落在永福村中心，是一个具有悠久历史和独特风貌的古镇。这里保存了大量明清时期的古建筑，如古民居、宗祠和古桥等。游客可以漫步在青石板铺就的古街巷中，感受传统水乡的浓郁氛围，品味江南古镇的风情</p>',

            introduction_audio:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/ad11dd227ff846d7b4389e152bdf8dd4.mp3',
          },

          labelIds: '',

          labelNames: '',

          commentNum: 0,

          scoreStar: 5,

          openId: '',

          haveClock: false,

          businessId: -1,

          cateringId: -1,

          businessName: '',

          contentName: '',

          businessIsShow: false,

          commanderName: '',

          cateringName: '',
        },

        {
          id: '1674661593417428994',

          templateTitle: '旅游景点',

          title: '青石岭',

          updateDate: '2023-06-30 14:21:29',

          hot: 0,

          clockIn: false,

          published: false,

          content: {
            arUrl: '',

            vrUrl: '',

            box_id: '',

            location: [
              120.37649,

              30.458053,
            ],

            detail_pic: [
              {
                url: 'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/%E5%B5%8A%E6%B3%97%E6%99%AF%E7%82%B93.jpg',
              },
            ],

            select_key: '',

            small_link: '',

            suggestHour: '',

            describetion: '青石岭位于永福村西北部，是一个山水相依的风景胜地。',

            first_figure:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/aab3a1901fbd4c5ea5486ea982809958.png',

            location_text: '青石岭',

            introduction_text:
              '<p>青石岭位于永福村西北部，是一个山水相依的风景胜地。这里有起伏的山峦、碧绿的竹林和潺潺的溪流。游客可以选择徒步登山，攀登山峰，俯瞰周围的美景；或者在青石岭湖畔漫步，感受清新宁静的自然环境。</p>',

            introduction_audio:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/aabb9eec2673477b96e4f56b6030d71e.mp3',
          },

          labelIds: '',

          labelNames: '',

          commentNum: 0,

          scoreStar: 5,

          openId: '',

          haveClock: false,

          businessId: -1,

          cateringId: -1,

          businessName: '',

          contentName: '',

          businessIsShow: false,

          commanderName: '',

          cateringName: '',
        },

        {
          id: '1674660346715090945',

          templateTitle: '旅游景点',

          title: '永福秘境',

          updateDate: '2023-06-30 14:05:16',

          hot: 0,

          clockIn: false,

          published: false,

          content: {
            arUrl: '',

            vrUrl: '',

            box_id: '',

            location: [
              120.375974,

              30.455608,
            ],

            detail_pic: [
              { url: 'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/25.jpg' },
            ],

            select_key: '',

            small_link: '',

            suggestHour: '',

            describetion: '永福秘境位于永福村东北部，是一个自然生态保护区。',

            first_figure:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/d8e049d0386d4880bb7771d799cfb2db.png',

            location_text: '永福秘境',

            introduction_text:
              '<p>永福秘境位于永福村东北部，是一个自然生态保护区。这里有茂密的原始森林、幽静的溪流和壮丽的瀑布。游客可以徒步穿越秘境，欣赏大自然的神奇美景，探索野生动植物的世界。</p>',

            introduction_audio:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/213b958637f34fd6859ae8c147550417.mp3',
          },

          labelIds: '',

          labelNames: '',

          commentNum: 0,

          scoreStar: 5,

          openId: '',

          haveClock: false,

          businessId: -1,

          cateringId: -1,

          businessName: '',

          contentName: '',

          businessIsShow: false,

          commanderName: '',

          cateringName: '',
        },

        {
          id: '1674659329684451330',

          templateTitle: '旅游景点',

          title: '龙山寺',

          updateDate: '2023-06-30 14:01:17',

          hot: 0,

          clockIn: false,

          published: false,

          content: {
            arUrl: '',

            vrUrl: '',

            box_id: '',

            location: [
              120.375091,

              30.45085,
            ],

            detail_pic: [
              {
                url: 'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/4c3b2d526eb8435cabd65ee28dab612c.png',
              },
            ],

            select_key: '',

            small_link: '',

            suggestHour: '',

            describetion: '龙山寺位于永福村南部，是一座古老而庄严的佛教寺庙。',

            first_figure:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/82e46dfe854c4281a95cb3d8f91fca07.png',

            location_text: '龙山寺',

            introduction_text:
              '<p>龙山寺位于永福村南部，是一座古老而庄严的佛教寺庙。寺内矗立着高耸入云的佛塔和古老的殿堂，供奉着众多佛像。游客可以在这里感受到宁静的氛围，欣赏古建筑的精湛工艺和佛教文化的魅力。</p>',

            introduction_audio:
              'https://meiyun-test.oss-cn-hangzhou.aliyuncs.com/upload/2023/06/30/6507076d5e0c464bab671e6172a92ca8.mp3',
          },

          labelIds: '',

          labelNames: '',

          commentNum: 0,

          scoreStar: 5,

          openId: '',

          haveClock: false,

          businessId: -1,

          cateringId: -1,

          businessName: '',

          contentName: '',

          businessIsShow: false,

          commanderName: '',

          cateringName: '',
        },
      ],
      markers: [
        {
          position: position,
        },
      ],
      userLocation: {
        lat: lat,
        lng: lon,
      },
      allMarkers: [
        {
          position: [119.4333, 29.546884],
          type: 1,
          name: '景点',
        },
        {
          position: [119.6333, 29.53884],
          type: 2,
          name: '停车场',
        },
        {
          position: [119.64545, 29.534884],
          type: 3,
          name: '卫生间',
        },
        {
          position: [119.343232, 29.46884],
          type: 4,
          name: '餐饮美食',
        },
        {
          position: [119.49885, 29.596884],
          type: 5,
          name: '酒店名宿',
        },
        {
          position: [119.4783, 29.5767484],
          type: 6,
          name: '购物场所',
        },
      ],
      markerList: [],
      location1: require('./assets/marker1.png'),
      location2: require('./assets/marker6.png'),
      location3: require('./assets/marker7.png'),
      location4: require('./assets/marker2.png'),
      location5: require('./assets/marker3.png'),
      location6: require('./assets/marker4.png'),
      location11: require('./assets/wenming_min.png'),
      location12: require('./assets/marker8.png'),
      locationSelected1: require('./assets/select-marker1.png'),
      locationSelected2: require('./assets/select-marker6.png'),
      locationSelected3: require('./assets/select-marker7.png'),
      locationSelected4: require('./assets/select-marker2.png'),
      locationSelected5: require('./assets/select-marker3.png'),
      locationSelected6: require('./assets/select-marker4.png'),
      locationSelected11: require('./assets/wenming_active.png'),
      locationSelected12: require('./assets/select-marker8.png'),
      redCircle: require('./img/redCircle.png'),
      bigBlackCircle: require('./img/bigBlackCircle.png'),
      smallBlackCircle: require('./img/smallBlackCircle.png'),
      detailIconNew: require('./img/detail.png'),
      daohangIcon: require('./img/daohang.png'),
      detailIcon: require('./img/detailIcon.png'),
      tringle: require('./img/tringle.png'),
      texts: [
        {
          position: position,
          text: title,
          offset: [0, -50],
        },
      ],
      shiyan: '默认',
      textList: [],
      textContent: '',
      textOffset: [0, -36],
    };
  },
  mounted() {
    // 添加图层
    this.$nextTick(() => {
      this.initialization();
      const timer = setTimeout(()=>{
        clearTimeout(timer)
        let o = amapManager.getMap();
         trafficLayer = new AMap.TileLayer.Traffic({
                    zIndex: 10
                });
                trafficLayer.setMap(o);
                trafficLayer.hide();
      },1500)
      
      // const timer = setTimeout(function () {
      //   clearTimeout(timer);
      //   AMap.plugin('AMap.Geolocation', () => {
      //     const geolocation = new AMap.Geolocation({
      //       enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      //       timeout: 0, // 超过10秒后停止定位，默认：无穷大
      //       maximumAge: 0, // 定位结果缓存0毫秒，默认：0
      //       noIpLocate: 0, // 是否禁止使用IP定位，默认值为0，可选值0-3  0: 可以使用IP定位    1: 手机设备禁止使用IP定位  2: PC上禁止使用IP定位  3: 所有终端禁止使用IP定位
      //       convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
      //       showButton: false, // 显示定位按钮，默认：true
      //       buttonPosition: 'RB', // 定位按钮停靠位置，默认："LB"，左下角
      //       showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
      //       showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
      //       panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
      //       zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
      //       extensions: 'all',
      //       pName: 'Geolocation',
      //     });
      //     geolocation.getCurrentPosition();
      //     AMap.event.addListener(geolocation, 'complete', onComplete);
      //     AMap.event.addListener(geolocation, 'error', onError);

      //     function onComplete(result) {
      //       // result是具体的定位信息
      //       console.log(result, '成功');
      //       this.shiyan=result||'失败了'
      //       // 获取到定位信息的处理逻辑
      //     }

      //     function onError(data) {
      //       // 定位出错
      //       console.log('高德 定位 失败 : ', data?.message);
      //       // 呼叫百度定
      //     }
      //   });
      //   }, 1500);
    });
  },
  created() {
    let latitude=window.localStorage.getItem(latitude)
    let longitude=window.localStorage.getItem(longitude)
    if(latitude&&longitude){
      this.latitude=latitude;
      this.longitude=longitude
    }
    if (this.ifReturnList) {
      this.getOneMarker();
    } else {
      this.selectedType = parseInt(this.topicSymbolIndex) || 1;
      this.pushMarkers();
    }
    window.localStorage.setItem('biaotoken',this.token)
  },
  methods: {
    gotoAttractions(){
      if(this.selectDevice.topicSymbol=='min-attractions-detail'&&this.selectDevice.id){
        wx.miniProgram.navigateTo({ url: `/pages/scenicSpotDetails/index?id=${this.selectDevice.id}` });
      }
    },
    gotoSearch() {
      this.$router.push({ name: 'searchDetails',query:{latitude:this.latitude,longitude:this.latitude,userlat:this.userLocation.lat,userlon:this.userLocation.lng} });
    },
    initialization() {
      let that = this;
      if (this.audiosrc && this.audiosrc.length > 0) {
        //设置src
        const timer = setTimeout(() => {
          //延时获取音频真正的duration
          var duration = this.$refs.audio.duration;
          var min = parseInt(duration / 60);
          var sec = parseInt(duration % 60);
          if (min.toString().length == 1) {
            min = `0${min}`;
          }
          if (sec.toString().length == 1) {
            sec = `0${sec}`;
          }
          that.audioDuration = this.$refs.audio.duration;
          that.showTime2 = `${min}:${sec}`;
          clearTimeout(timer);
          console.log(`${min}:${sec}`);
        }, 1000);
      }
    },
    rad(d) {
      return (d * Math.PI) / 180.0;
    },
    viodoUpdate() {
      const that = this;
      console.log();
      if (that.$refs.audio.currentTime / that.$refs.audio.duration == 1) {
        // 播放完成
        that.audioTime = 0;
        that.showTime1 = '00:00';
        that.isPlayAudio = false;
      } else {
        var per =
          (that.$refs.audio.currentTime / that.$refs.audio.duration) * 100;
        that.audioTime = per;
        that.showTime1 = that.showTimeFormat(that.$refs.audio.currentTime);
      }
    },
    showTimeFormat(duration) {
      let min = parseInt(duration / 60);
      let sec = parseInt(duration % 60);
      if (min.toString().length == 1) {
        min = `0${min}`;
      }
      if (sec.toString().length == 1) {
        sec = `0${sec}`;
      }
      return `${min}:${sec}`;
    },
    playAudio() {
      //获取播放状态和当前播放时间
      let isPlayAudio = this.isPlayAudio;
      this.$refs.audio.pause();
      //更改播放状态
      this.isPlayAudio = !isPlayAudio;
      if (!isPlayAudio) {
        //如果在暂停，获取播放时间并继续播放
        this.$refs.audio.play();
      }
    },
    sliderChange(value) {
      //获取进度条百分比
      this.audioTime = value;
      let duration = this.$refs.audio.duration;
      //根据进度条百分比及歌曲总时间，计算拖动位置的时间
      value = parseInt((value * duration) / 100);
      //更改状态
      this.audioSeek = value;
      this.isPlayAudio = true;
      //调用seek方法跳转歌曲时间
      this.$refs.audio.currentTim = value;
      //播放歌曲
      this.$refs.audio.play();
    },
    goToCenter() {
      this.currentPositionShow = false;
      AMap.plugin('AMap.Geolocation', () => {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 10000, // 设置定位超时时间，默认：无穷大
          offset: [10, 20], // 定位按钮的停靠位置的偏移量
          zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          position: 'RB', //  定位按钮的排放位置,  RB表示右下
        });

        geolocation.getCurrentPosition((status, result) => {
          console.log(status, 'status', result);
          if (status == 'complete') {
            let center = [...this.center];
            let { lat, lng } = result.position;
            if (center[0] == lng) {
              lng = lng - 0.000001;
            }
            if (center[1] == lat) {
              lat = lat - 0.000001;
            }
            this.center = [lng, lat];
            console.log(this.center, 'this.center');
          } else {
            if (this.center === position) {
              this.center = [position[0] - 0.000001, position[1] - 0.000001];
            } else {
              this.center = position;
            }
          }
          this.currentPosition = [...this.center];
          this.currentPositionShow = true;
        });
      });

      // if(this.center === position){
      //     this.center = [position[0]-0.000001 ,position[1]-0.000001];
      // }else{
      //     this.center = position;
      // }
    },
    getCenterByCode() {
      const this_ = this;
      const params = {
        keywords: this.tenantId.slice(0, 6),
        key: 'b730f143b581509e91e4e00e70d11381',
      };
      axios.defaults.headers = {};
      axios
        .get('https://restapi.amap.com/v3/config/district', { params: params })
        .then((res) => {
          let center = res.data.districts[0].center.split(',');
          console.log(center);
          const centerTrue = [];
          center.forEach((item) => {
            centerTrue.push(parseFloat(item));
          });
          this_.center = [...centerTrue];
        });
    },
    getOneMarker() {
      const this_ = this;
      const contentId = this_.contentId;
      const topicSymbol = this_.topicSymbol;
      const tenantId = this_.tenantId;
      const mockData = this_.$api
        .getContentDetail({
          contentId: contentId || '',
          topicSymbol: topicSymbol,
          tenantId: 331125105201,
        })
        .then((res) => {
          if (res.data.code === 10000) {
            const item = this_.mockData;
            console.log(item);
            // 放到点中去
            const markers = [];
            if (topicSymbol === 'min-souvenir-detail') {
              jingduList += parseFloat(item.content.location[0]) || 0;
                weiduList += parseFloat(item.content.location[1]) || 0;
                const marker = {
                  juli: this.getDistance(
                    parseFloat(item.content.location[1]),
                    parseFloat(item.content.location[0]),
                    parseFloat(this.userLocation.lat),
                    parseFloat(this.userLocation.lng)
                  ),
                  id: item.id,
                  addressText:
                    item.content.addressText ||
                    item.content.location_text ||
                    item.content.address_text,
                  topicSymbol: topicSymbolItem,
                  position: item.content.location,
                  type: this_.getType(topicSymbolItem),
                  name: item.title,
                  select: false,
                  audiosrc: item.content.introduction_audio,
                  headPic: item.content.detail_pic,
                  describetion: item.content.describetion
                    ? item.content.describetion
                    : '',
                };
              // const marker = {
              //   id: item.id,
              //   topicSymbol: topicSymbol,
              //   position: item.content.purchase_point,
              //   type: this_.getType(topicSymbol),
              //   name: item.content.purchase_place,
              //   select: true,
              //   audiosrc: item.introduction_audio,
              // };
              markers.push(marker);
              this_.markerList = markers;
              // 更新地图中心点
              this_.center = item.content.purchase_point;
              // 设置景点文字
              this_.lookText(marker);
            } else {
                jingduList += parseFloat(item.content.location[0]) || 0;
                weiduList += parseFloat(item.content.location[0]) || 0;
                const marker = {
                  juli: this.getDistance(
                    parseFloat(item.content.location[0]),
                    parseFloat(item.content.location[0]),
                    parseFloat(this.userLocation.lat),
                    parseFloat(this.userLocation.lng)
                  ),
                  id: item.id,
                  addressText:
                    item.content.addressText ||
                    item.content.location_text ||
                    item.content.address_text,
                  topicSymbol: topicSymbolItem,
                  position: item.content.location,
                  type: this_.getType(topicSymbolItem),
                  name: item.title,
                  select: false,
                  audiosrc: item.content.introduction_audio,
                  headPic: item.content.detail_pic,
                  describetion: item.content.describetion
                    ? item.content.describetion
                    : '',
                };
              // const marker = {
              //   id: item.id,
              //   topicSymbol: topicSymbol,
              //   position: item.content.location,
              //   type: this_.getType(topicSymbol),
              //   name: item.title,
              //   select: true,
              //   audiosrc: item.introduction_audio,
              // };
              markers.push(marker);
              this_.markerList = markers;
              // 更新地图中心点
              this_.center = item.content.location;
              this_.zoom = 13;
              // 设置景点文字
              this_.lookText(marker);
            }
          } else {
            Notify({ type: 'warning', message: res.data.msg });
          }
        });
    },
    trafficLayerClick(){
      this.trafficLayer=!this.trafficLayer
      if (this.trafficLayer) {
        trafficLayer && trafficLayer.show();
      }else {
        trafficLayer && trafficLayer.hide();
      }
    },
    pushMarkers() {
      let this_ = this;
      let markers = [];
      let index = 0;
      const type = this_.selectedType;
      const topicSymbol = this_.getTopicSymbol(parseInt(type));
      let parkingList = [];
      topicSymbol.forEach((topicSymbolItem) => {
        this_.$api
          .getContentByPage({
            page: 1,
            size: 1000,
            topicSymbol: topicSymbolItem,
            tenantId: 331125105201,
          })
          .then((res) => {
            if (res.data.code === 10000) {
              const list = res.data.data.records;
              console.log(res.data, 1111222);
              if (list.length === 0) {
                this_.getCenterByCode();
                return;
              }
              // 获取经度数组和纬度数组
              let jingduList = 0;
              let dianjimarker=''
              let weiduList = 0;
              list.forEach((item) => {
                console.log(item, 111122222);
                jingduList += parseFloat(item.content.location[0]) || 0;
                weiduList += parseFloat(item.content.location[1]) || 0;
                const marker = {
                  juli: this.getDistance(
                    parseFloat(item.content.location[1]),
                    parseFloat(item.content.location[0]),
                    parseFloat(this.userLocation.lat),
                    parseFloat(this.userLocation.lng)
                  ),
                  id: item.id,
                  addressText:
                    item.content.addressText ||
                    item.content.location_text ||
                    item.content.address_text,
                  topicSymbol: topicSymbolItem,
                  position: item.content.location,
                  type: this_.getType(topicSymbolItem),
                  name: item.title,
                  select: false,
                  audiosrc: item.content.introduction_audio,
                  headPic: item.content.detail_pic,
                  describetion: item.content.describetion
                    ? item.content.describetion
                    : '',
                };
                if (topicSymbol == 'min-park-lot-list') {
                  marker.count = item.content.parking_amount;
                  marker.remain = null;
                  marker.ifMianfei =
                    item.content.parking_id === 1 ? true : false;
                }
                marker.events = {
                  click: () => {
                    marker.select = true;
                    this_.lookText(marker);
                  },
                };
                if (marker.id == this.id) {
                  dianjimarker=marker
                }
                markers.push(marker);
              });
              jingduList = parseFloat(jingduList / list.length).toFixed(6);
              weiduList = parseFloat(weiduList / list.length).toFixed(6);
              this_.center = [jingduList, weiduList];
              if(dianjimarker){
                this_.lookText(dianjimarker);
              }
              this_.zoom = 13;
            } else {
              Notify({ type: 'warning', message: res.data.msg });
            }
          });
      });
      this_.markerList = markers;
    },
    changeSelectType(type) {
      // 为了防止从搜索页面跳到这里弹窗多次打开所以类型切换直接把id置空就不会自动弹出来弹窗了
      this.id = '';
      // if(type!=1){
      //     this.markerList=[];
      // }
      // else{
      //     this.pushMarkers()
      // }
      // this.selectedType = type;
     if (type == 18) {
        this.selectedType = type;
        // wx.miniProgram.postMessage({ data: {type:'luxian'} });
        wx.miniProgram.navigateTo({ url: '/pages/traffic/index' });
      } else {
        this.selectDevice = {};
        this.textList = [];
        this.textContent = '';
        this.selectedType = type;
        this.pushMarkers();

      }
    },
    getType(topicSymbol) {
      let type = '';
      switch (topicSymbol) {
        case 'min-attractions-detail':
          type = 1;
          break;
        case 'min-park-lot-list':
          type = 2;
          break;
        case 'min-toilet-list':
          type = 3;
          break;
        case 'min-catering-detail':
          type = 4;
          break;
        case 'min-food-detail':
          type = 4;
          break;
        case 'min-hotel-detail':
          type = 5;
          break;
        case 'min-shop-detail':
          type = 6;
          break;
        case 'min-souvenir-detail':
          type = 7;
          break;
        case 'min-entertainment-detail':
          type = 11;
          break;
        case 'min-gasStation-detail':
          type = 12;
          break;
      }
      return type;
    },
    getDistance(lat1, lng1, lat2, lng2) {
      console.log(lat1, lng1, lat2, lng2,'数据')
      let EARTH_RADIUS = 6378.137;
      let radLat1 = this.rad(lat1);
      let radLat2 = this.rad(lat2);
      let a = radLat1 - radLat2;
      let b = this.rad(lng1) - this.rad(lng2);
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * EARTH_RADIUS;
      //s = Math.round(s * 10000d) / 10000d;
      // s = Math.round(s * 10000) / 10000;
      s = parseInt(s * 1000); //乘以1000是换算成米
      s = s < 1000 ? s + '米' : (s / 1000).toFixed(1) + '公里';
      return s;
    },
    getTopicSymbol(type) {
      let topicSymbol = '';
      switch (type) {
        case 1:
          topicSymbol = ['min-attractions-detail'];
          break;
        case 2:
          topicSymbol = ['min-park-lot-list'];
          break;
        case 3:
          topicSymbol = ['min-toilet-list'];
          break;
        case 4:
          topicSymbol = ['min-catering-detail', 'min-food-detail'];
          break;
        case 5:
          topicSymbol = ['min-hotel-detail'];
          break;
        case 6:
          topicSymbol = ['min-shop-detail'];
          break;
        case 11:
          topicSymbol = ['min-entertainment-detail'];
          break;
        case 12:
          topicSymbol = ['min-gasStation-detail'];
          break;
      }
      return topicSymbol;
    },
    getTypeName(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '景点';
          break;
        case 2:
          name = '停车场';
          break;
        case 3:
          name = '公厕';
          break;
        case 4:
          name = '餐饮美食';
          break;
        case 5:
          name = '酒店名宿';
          break;
        case 6:
          name = '购物场所';
          break;
        case 11:
          name = '娱乐';
          break;
        case 12:
          name = '加油站';
          break;
      }
      return name;
    },
    // 获取背景图
    getBackIconUrl(amount) {
      if (!amount) {
        return null;
      }
      console.log(amount);
      amount = Number(amount);
      if (amount == 0) {
        return './img/redCircle.png';
      }
      if (amount > 0 && amount < 100) {
        return this.smallBlackCircle;
      }
      if (amount > 100) {
        return './img/bigBlackCircle.png';
      }
    },
    getIcon(type, select, amount) {
      const this_ = this;
      let pic = '';
      switch (type) {
        case 1:
          if (select) {
            pic = `<img src=${this_.locationSelected1} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location1} style='width:42px;height:42px'/>`;
          }
          break;
        case 2:
          if (amount) {
            if (select) {
              pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${
                amount == 0
                  ? this_.redCircle
                  : amount > 99
                  ? this_.bigBlackCircle
                  : this_.smallBlackCircle
              } style='width:${
                String(amount).length == 3 ? '15px' : '12px'
              };height:${
                String(amount).length == 3 ? '17px' : '14px'
              };position: absolute;top: ${
                String(amount).length == 3 ? '0px' : '4px'
              };left: ${
                String(amount).length == 3 ? '3.5px' : '5px'
              };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${
                String(amount).length == 3 ? '-1px' : '1px'
              };left: ${
                String(amount).length == 3
                  ? '6.4px'
                  : String(amount).length == 2
                  ? '7.4px'
                  : '9px'
              }'>${amount}</span></div><img src=${
                this_.locationSelected2
              } style='width:42px;height:42px'/></div>`;
            } else {
              pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${
                amount == 0
                  ? this_.redCircle
                  : amount > 99
                  ? this_.bigBlackCircle
                  : this_.smallBlackCircle
              } style='width:${
                String(amount).length == 3 ? '15px' : '12px'
              };height:${
                String(amount).length == 3 ? '17px' : '14px'
              };position: absolute;top: ${
                String(amount).length == 3 ? '0px' : '4px'
              };left: ${
                String(amount).length == 3 ? '3.5px' : '5px'
              };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${
                String(amount).length == 3 ? '-1px' : '1px'
              };left:${
                String(amount).length == 3
                  ? '6.4px'
                  : String(amount).length == 2
                  ? '7.4px'
                  : '9px'
              }'>${amount}</span></div><img src=${
                this_.location2
              } style='width:42px;height:42px'/></div>`;
            }
          } else {
            if (select) {
              pic = `<img src=${this_.locationSelected2} style='width:42px;height:42px'/>`;
            } else {
              pic = `<img src=${this_.location2} style='width:42px;height:42px'/>`;
            }
          }

          break;
        case 3:
          if (select) {
            pic = `<img src=${this_.locationSelected3} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location3} style='width:42px;height:42px'/>`;
          }
          break;
        case 4:
          if (select) {
            pic = `<img src=${this_.locationSelected4} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location4} style='width:42px;height:42px'/>`;
          }
          break;
        case 5:
          if (select) {
            pic = `<img src=${this_.locationSelected5} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location5} style='width:42px;height:42px'/>`;
          }
          break;
        case 6:
          if (select) {
            pic = `<img src=${this_.locationSelected6} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location6} style='width:42px;height:42px'/>`;
          }
          break;
        case 11:
          if (select) {
            pic = `<img src=${this_.locationSelected11} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location11} style='width:42px;height:42px'/>`;
          }
          break;
        case 12:
          if (select) {
            pic = `<img src=${this_.locationSelected12} style='width:42px;height:42px'/>`;
          } else {
            pic = `<img src=${this_.location12} style='width:42px;height:42px'/>`;
          }
          break;
      }
      return pic;
    },
    lookText(marker) {
      console.log('触发了',marker);
      const this_ = this;
      const list = this_.markerList;
      list.forEach((item) => {
        if (item.id == marker.id) {
          item.select = true;
        } else {
          item.select = false;
        }
      });

      this.selectDevice = marker;
      this.audiosrc = '';
      this.audioTime = '';
      this.audiosrc = marker.audiosrc;
      this.initialization();
      this.isshow = true;
      setTimeout(() => {
        this.isshow = false;
      }, 1000);
      this.center = [marker.position[0] - 0.000001, marker.position[1] - 0.000001];
      // //   this_.selectDevice = detailItem;
      //   this_.$api
      //     .getContentDetail({
      //       contentId: marker.id || '',
      //       topicSymbol: marker.topicSymbol,
      //       tenantId: this_.tenantId,
      //     })
      //     .then((res) => {
      //       if (res.data.code === 10000) {
      //         const item = res.data.data;
      //         const detailItem = {
      //           ...item.content,
      //           type: marker.type,
      //         };
      //         detailItem.id = item.id;
      //         detailItem.topicSymbol = marker.topicSymbol;
      //         // 纪念品字段有点特殊
      //         if (marker.topicSymbol === 'min-souvenir-detail') {
      //           detailItem.headPic = item.content.title_img;
      //           detailItem.title = item.content.purchase_place;
      //           detailItem.location = item.content.purchase_point;
      //         } else {
      //           detailItem.headPic = item.content.first_figure;
      //           detailItem.title = item.title;
      //           detailItem.location = item.content.location;
      //         }

      //         if (marker.topicSymbol == 'min-park-lot-list') {
      //           detailItem.parkingFlag = 1;
      //           detailItem.ifMianfei = marker.ifMianfei;
      //           detailItem.total = marker.count;
      //           detailItem.free = marker.remain;
      //         } else {
      //           detailItem.parkingFlag = 0;
      //         }
      //         if (item.id == '1501730089888104449') {
      //           detailItem.describetion =
      //             '免费WIFI、临时休息、免费开水、免费手机充电、免费阅读、免费资料打印、洗手间免费开放、应急药品、代办代跑。联系方式：0578 —5533000';
      //         } else if (item.id == '1501730343828045826') {
      //           detailItem.describetion =
      //             '纠纷调解、免费法律咨询。联系方式：0578—5533085';
      //         } else if (item.id == '1501730708187234305') {
      //           detailItem.describetion =
      //             '图书借阅、洗手间免费开放、免费开水。联系方式：0578—5533058';
      //         } else if (item.id == '1501730944339132418') {
      //           detailItem.describetion =
      //             '健康咨询、免费测血压血糖、应急药品。联系方式：0578—5151207';
      //         } else if (item.id == '1501731358841225217') {
      //           detailItem.describetion =
      //             '法律咨询、洗手间免费开放、免费开水。联系方式：0578—5151109';
      //         } else if (item.id == '1501731837755244546') {
      //           detailItem.describetion = '免费借用雨伞。联系方式：0578—5151155';
      //         } else if (item.id == '1501732182145351681') {
      //           detailItem.describetion =
      //             '免费WIFI、免费资料打印、免费新能源车充电。联系方式：0578—515533';
      //         } else if (item.id == '1501732479945129985') {
      //           detailItem.describetion =
      //             '浙江省非物质文化遗产——瓯江帆船技艺展示。联系方式：15990433096';
      //         } else if (item.id == '1501732743771045890') {
      //           detailItem.describetion = '免费开水。联系方式：13754263827';
      //         } else if (item.id == '1501732984138219521') {
      //           detailItem.describetion = '快递代发。联系方式：15988033332';
      //         }
      //       } else {
      //         Notify({ type: 'warning', message: res.data.msg });
      //       }
      //     });
      //   this_.clearLocation();
    },
    goToDetailPage(marker) {
      const that = this;
      console.log(marker);
      const url = that.ifFengkou
        ? that.getFengkouPageUrl(marker.topicSymbol)
        : that.getPageUrl(marker.topicSymbol);
      if (!that.ifReturnList) {
        wx.miniProgram.navigateTo({
          url: `${url}?id=${marker.id}`,
          events: {
            // 为指定事件添加一个监听器，获取被打开页面传送到当前页面的数据
            acceptDataFromOpenedPage: function (data) {
              console.log(data);
            },
            someEvent: function (data) {
              console.log(data);
            },
          },
          success: function (res) {
            // 通过eventChannel向被打开页面传送数据
            console.log(res);
          },
        });
        // wx.miniProgram.postMessage({ data: marker });
      }
    },
    // 规划路径
    gotoLocation(selectDevice) {
      const this_ = this;
      const latitude = this_.latitude;
      const longitude = this_.longitude;
      let o = amapManager.getMap();
      let driving = new AMap.Driving({
        map: o,
        // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
        policy: AMap.DrivingPolicy.LEAST_TIME,
      });

      if (!longitude) {
        Notify({ type: 'warning', message: '未授权定位或定位失败' });
        return false;
      }
      // 打开手机app的规划路线
      wx.miniProgram.navigateTo({
        url: `/pages/mapViewChoose/mapViewChoose?longitude=${selectDevice.position[0]}&latitude=${selectDevice.position[1]}&title=${selectDevice.name}`,
      });
    },
    // 清除路径
    clearLocation() {
      let map = amapManager.getMap();
      map.clearMap();
    },
    // 获取页面
    getPageUrl(topicSymbol) {
      let url = '';
      switch (topicSymbol) {
        case 'min-attractions-detail':
          url = '/pages/theGreatView/greatViewDetail/greatViewDetail';
          break;
        case 'min-catering-detail':
          url = '/pages/restaurantAndFood/restaurantDetail/restaurantDetail';
          break;
        case 'min-food-detail':
          url = '/pages/restaurantAndFood/foodDetail/foodDetail';
          break;
        case 'min-hotel-detail':
          url = '/pages/hotel/hotelDetail/hotelDetail';
          break;
        case 'min-shop-detail':
          url = '/pages/shoppingMall/shoppingMallDetail/shoppingMallDetail';
          break;
      }
      return url;
    },
    // 汾口镇的详情页
    getFengkouPageUrl(topicSymbol) {
      let url = '';
      switch (topicSymbol) {
        case 'min-attractions-detail':
          url = '/pages/trevalPages/greatViewDetail/greatViewDetail';
          break;
        case 'min-catering-detail':
          url = '/pages/trevalPages/restaurantDetail/restaurantDetail';
          break;
        case 'min-food-detail':
          url = '/pages/trevalPages/foodDetail/foodDetail';
          break;
        case 'min-hotel-detail':
          url = '/pages/trevalPages/hotelDetail/hotelDetail';
          break;
        case 'min-shop-detail':
          url = '/pages/trevalPages/shoppingMallDetail/shoppingMallDetail';
          break;
      }
      return url;
    },
    goBack() {
      this.$router.go(-1);
    },
    detailModalClick() {
      this.selectDevice = {};
      this.$refs.audio.pause();
      this.isPlayAudio = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$px: 1rem/37.5;
.describetion {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 330px !important;
  color: #333333;
  margin: 10px 21px 0 22px;
  display: -webkit-box; /* 将容器以弹性盒子形式布局 */
  -webkit-line-clamp: 2; /* 限制文本显示为两行 */
  -webkit-box-orient: vertical; /* 将弹性盒子的主轴方向设置为垂直方向 */
  overflow: hidden; /* 隐藏容器中超出部分的内容 */
}
.serchbox {
  padding-left: 5 * $px;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 9999;
  top: 12 * $px;
  width: 162 * $px;
  height: 36 * $px;
  background: #ffffff;
  border-radius: 18 * $px;
  border: 1 * $px solid #eeeeee;
  left: 16 * $px;
  span {
    font-size: 14 * $px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-left: 3 * $px;
  }
}
.audioLine {
  height: 35px;
  position: relative;
  margin: 8px 16px;
  border-radius: 46px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 99999;
  img{
    width: 24px;
    height: 24px;
    margin-left: 5px;
  }
  .playArea {
    width: 24px;
    height: 24px;
    margin-left: 5px;
  }
  .tiptitle {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 0 5px;
  }
  .slider {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .audioFont {
    color: #0069ff;
    font-size: 16px;
    margin-right: 5px;
  }
}

.amap-page-container {
  height: 100%;
  position: relative;
}

.amap-demo {
  height: 100%;
}

.bottonArea {
  position: absolute;
  bottom: 1rem;
  left: 0.8rem;
  border-radius: 0.16rem;
}

.bottomLine {
  height: 168 * $px;
  background: #ffffff;
  box-shadow: 0px 2 * $px 4 * $px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8 * $px 8 * $px 0px 0px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}

.bottom-title {
  font-size: 15 * $px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 21 * $px;
  padding: 16 * $px 13 * $px 12 * $px;
}

.bottom-icon-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16 * $px;
}

.iconArea {
  height: 95 * $px;
  width: 63 * $px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    display: block;
    width: 39 * $px;
    height: 39 * $px;
  }

  > span {
    font-size: 13 * $px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18 * $px;
    padding-top: 5 * $px;
  }
}

.iconAreaSelect {
  background: #e1f4ff;
  border-radius: 16 * $px;

  > span {
    font-size: 14 * $px;
    font-weight: bold;
    color: #333;
  }
}

.selectedFont {
  margin-top: 0.1rem;
  color: #1677ff;
}

.noselectFont {
  margin-top: 0.1rem;
  color: #999999;
}
.describetion {
}
.detailTab {
  width: 8.93333rem;
  height: 1.38667rem;
  position: absolute;
  left: 0.53333rem;
  bottom: 2.1333rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
  z-index: 99;
}

.detailTabParking {
  width: 8.93333rem;
  height: 1.38667rem;
  position: absolute;
  left: 0.53333rem;
  bottom: 2.1333rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
  z-index: 99;
  box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
}

.parkingTab {
  height: 100%;
  width: 7.5rem;
}

.parkingTabTitleLine {
  height: 20 * $px;
  margin-top: 6 * $px;
  margin-left: 10 * $px;
  font-size: 14 * $px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.parkingTabFont {
  margin-left: 0.24rem;
  height: 16 * $px;
  width: 30 * $px;
  color: #ff7e48;
  border: #ff7e48 0.75 * $px solid;
  box-sizing: border-box;
  border-radius: 6 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallSize {
  font-size: 12px;
  -webkit-transform-origin-x: 0;
  transform: scale(0.75);
  -webkit-transform: scale(0.75);
}

.oSmallSize {
  font-size: 9px;
}

.selectDeviceImg {
  position: absolute;
  height: 52 * $px;
  width: 52 * $px;
  left: 10 * $px;
  bottom: 10 * $px;
  box-shadow: 0px 1 * $px 2 * $px 0px rgba(243, 242, 242, 1);
}

.selectDeviceTitle {
  margin-left: 71 * $px;
  color: #3f3f3f;
  font-size: 14 * $px;
  height: 100%;
  width: 140 * $px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.checkDetail {
  font-size: 10 * $px;
  color: #c0c0c0;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

.checkDetailAndDaohang {
  height: 100%;
  width: 50 * $px;
  display: flex;
  margin-right: 8 * $px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkDetailArea {
  position: absolute;
  top: 0rem;
  right: 1.62rem;
}

.checkDaohangArea {
  position: absolute;
  top: 0.15rem;
  right: 0.16rem;
}

.parkingIconArea {
  width: 22px;
  height: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.small-font {
  font-size: 14px;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.5);
}

.smallsize-font {
  font-size: 7px;
}

.fontDisplay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.parkingDetailLine {
  height: 14 * $px;
  margin-left: 10 * $px;
  color: #989898;
  font-size: 10 * $px;
}

.detailTabArea {
  width: 120 * $px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.detailTabOutImg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DetailImg {
  width: 17 * $px;
  height: 17 * $px;
}

.parkingTab-left {
  width: 260 * $px;
  height: 100%;
}

.parkingTab-right {
  width: 70 * $px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.rightLine {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16 * $px;
  top: 24 * $px;
  background-color: #ffffff;
  width: 40 * $px;
  box-shadow: 0 * $px 2 * $px 4 * $px 0 * $px rgba(0, 0, 0, 0.2);
  border-radius: 4 * $px;
  z-index: 99;

  .right-item {
    padding: 8 * $px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
      content: '';
      width: 32 * $px;
      height: 1px;
      background: #d8d8d8;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(4) {
      &::after {
        display: none;
      }
    }

    > img {
      display: block;
      width: 22 * $px;
      height: 22 * $px;
    }

    > span {
      font-size: 10 * $px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2f3440;
      line-height: 14 * $px;
      padding-top: 2 * $px;
    }
  }

  .right-item-select {
    background-color: #419eff;

    &::after {
      display: none;
    }

    > span {
      color: #ffffff;
    }
  }
}

.right-location {
  position: absolute;
  right: 12 * $px;
  top: 304 * $px;

  img {
    width: 46px;
    height: 56px;
  }
}

.detail-modal-wrap {
  width: 100%;
  height: 30%;
  position: absolute;
  //   bottom:0;
  left: 0;
  bottom: 0;
  z-index: 90;

  .detail-modal-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    // bottom:0;
    left: 0;
    z-index: 80;
  }
}

.detail-modal {
  box-shadow: 0 * $px 2 * $px 4 * $px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8 * $px 8 * $px 0 0;
  width: 375 * $px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 16 * $px;
  .title-wrap {
    margin: 0 16 * $px 16 * $px;
    margin-bottom: 0px;
    padding-top: 8 * $px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detail-title {
      width: 289px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      color: #333333;
      line-height: 25px;
      margin-left: 8px;
      margin-right: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .detail-close {
      background: url(https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E5%AF%BC%E8%A7%88%E5%85%B3%E9%97%AD%E6%8C%89%E9%92%AE.png)
        no-repeat;
      background-size: 100% 100%;
      width: 32 * $px;
      height: 24 * $px;
    }
  }
  .detail-address {
    margin: 0 16px 16px;
    align-items: center;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    .distance {
      width: 100px;
      margin-left: 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .addressTitle {
      display: inline-block;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-left: 4px;
      width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  .detail-desc-wrap {
    padding: 0 32px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-location {
      display: block;
      width: 22px;
      height: 24px;
    }

    .icon-arrow {
      display: block;
      width: 18px;
      height: 18px;
    }

    > p {
      margin: 0 8px;
      font-size: 14px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #999999;
      line-height: 20px;
      flex: 1;
    }
  }

  .detail-pic-wrap {
    height: 93px;
    margin: 0 24px;

    > img {
      width: 107px;
      height: 93px;
      border-radius: 4px;
    }
  }

  .detail-parking-desc {
    font-size: 16 * $px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    line-height: 24 * $px;
    text-indent: 24 * $px;
    padding: 4 * $px 0;
  }

  .go-location {
    width: 327 * $px;
    height: 49 * $px;
    background: #1777ff;
    border-radius: 25 * $px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18 * $px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 25 * $px;
    margin: 10 * $px auto;
  }
}
</style>
<style>
.amap-icon img {
  width: 30px;
  height: 30px;
}

.amap-overlay-text-container {
  font-size: 0.4rem;
}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.amap-geo {
  display: none;
}
</style>
